import React from 'react';
import serviceLogo from '../../assets/img/service.png';
import pencilIcon from '../../assets/icons/pencil-alt.svg';
import mobileIcon from '../../assets/icons/mobile.svg';
import paletteIcon from '../../assets/icons/palette.svg';
import chartIcon from '../../assets/icons/bar-chart.svg';
import lightBulbIcon from '../../assets/icons/light-bulb.svg';

const Services = () => {
    return (    
    <section className="section-margin">
    <div className="container">
      <div className="section-intro pb-85px text-center">
        <h2 className="section-intro__title" id='services'>Services We Offer</h2>
        <p className="section-intro__subtitle"></p>
      </div>

      <div className="row">
        <div className="col-lg-6">

          <div className="row offer-single-wrapper">
            <div className="col-lg-6 offer-single">
              <div className="card offer-single__content text-center">
                <span className="offer-single__icon">
                  <img src={pencilIcon} alt="pencil icon" height="30" width="30" />
                </span>
                <h4>Software Development</h4>
                <p>Bespoke software solutions</p>
              </div>
            </div>
            
            <div className="col-lg-6 offer-single">
              <div className="card offer-single__content text-center">
                <span className="offer-single__icon">
                <img src={mobileIcon} alt="mobile icon" height="30" width="30" />
                  {/* <i className="ti-ruler-pencil"></i> */}
                </span>
                <h4>Mobile App Development</h4>
                <p>Mobile Apps</p>
              </div>
            </div>
          </div>

          <div className="row offer-single-wrapper">
            <div className="col-lg-6 offer-single">
              <div className="card offer-single__content text-center">
                <span className="offer-single__icon">
                <img src={paletteIcon} alt="palette icon" height="30" width="30" />
                  {/* <i className="ti-cut"></i> */}
                </span>
                <h4>UI/UX Design</h4>
                <p>Innovative desing and experience</p>
              </div>
            </div>
            
            <div className="col-lg-6 offer-single">
              <div className="card offer-single__content text-center">
                <span className="offer-single__icon">
                <img src={chartIcon} alt="chart icon" height="30" width="30" />
                </span>
                <h4>Data Insight</h4>
                <p>Machine Learning</p>
                <p>Data Science</p>
                <p>Airtificial Intelligence</p>
              </div>
            </div>
          </div>

          <div className="row offer-single-wrapper">
            <div className="col-lg-6 offer-single">
              <div className="card offer-single__content text-center">
                <span className="offer-single__icon">
                <img src={lightBulbIcon} alt="light bulb icon" height="30" width="30" />
                </span>
                <h4>Consultancy</h4>
                <p>Cloud</p>
                  <p>Technology Adaptation</p>
                  <p>Legacy Software </p>
              </div>
            </div>
          </div>

        </div>
        <div className="col-lg-6">
          <div className="offer-single__img">
            <img className="img-fluid" width="625" height="600" src={serviceLogo} alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
    );
}

export default Services;