/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-scroll";
import Banner from '../../assets/img/banner/banner.png';

const Hero = () => {
    return (
       <>
    <section className="hero-banner mb-0px">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="hero-banner__img">
              <img className="img-fluid" src={Banner} alt="banner image" />
            </div>
          </div>
          <div className="col-lg-5 pt-0">
            <div className="hero-banner__content">
              <h1>Home of bespoke software solution</h1>
              <p>We are proud of working with our clients to deliver a bespoke solution under the right professional guidance and advice.</p>
              <Link activeClass="active" className="button bg nav-link" to="contact" spy={true} smooth={true} offset={-70} duration={1000}>Get in touch</Link>
              {/* <a className="button bg" href="#contact">Get in touch</a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
    );
}

export default Hero;