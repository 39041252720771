import aboutLogo from '../../assets/img/about.png';


const About = () => {
    return (
        <section className="section-padding--small bg-magnolia">
        <div className="container" id='about'>
          <div className="row no-gutters align-items-center">
            <div className="col-md-5 mb-5 mb-md-0">
              <div className="about__content">
                <h2>Who we are</h2>
                <p>We are a driven by quality, obsessed with high standard. We don't just build a working software but we ensure that best practices are enforced even for 
                  the parts you don't see.
                </p>
                <p>
                We develop our products as a craft making sure attention is given to every detail with every decision taken considered critical to the success of the final product.
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="about__img">
                <img className="img-fluid" src={aboutLogo} alt=""/>
              </div>
            </div>
          </div>
        </div>
    </section>
    )
}

export default About;
