import $ from 'jquery';


    export function HideShowHeader() {
        
        var didScroll;
        var lastScrollTop = 0;
        var delta = 50;
        var navbarHeight = $('header').height() + 50;
        var navbarHideAfter = navbarHeight;
    
        $(window).scroll(function (event) {
            didScroll = true;
        });
    
        if ($('.scroll-hide').length ) {
            setInterval(function () {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 100);
        }
        return false;
    
        function hasScrolled() {
            var st = $(window).scrollTop();
    
            if (Math.abs(lastScrollTop - st) <= delta) return;
    
            if (st > lastScrollTop && st > navbarHideAfter) { // when scrolling up
                if ($('.scroll-hide').length > 0) {
                    $('header').addClass('hide');
                }
            } else { // when scrolling down
                if ($('.scroll-hide').length > 0) {
                    if (st + $(window).height() < $(document).height()) {
                        $('header').removeClass('hide');
                        $('.header.transparent').addClass('scroll');
                    }
                }
    
                if ($(window).scrollTop() < 300) {
                    $('.header.transparent').removeClass('scroll');
                }
            }
    
            lastScrollTop = st;
        }
    }