/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from '../../../assets/img/logo.png';

import $ from "jquery";
import { HideShowHeader } from "./plugin.js";
import { useEffect } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";

import './index.scss';

const Header = () => {

  useEffect(() => {
    $(document).ready(function () {
      console.log("document ready")
      HideShowHeader();
    });
  });

  const scrollToTop = () => {
    scroll.scrollToTop();
};


    return (  
        <header className="header_area header transparent scroll-hide">
          <div className="main_menu">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container box_1620">
                {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                <a className="navbar-brand logo_h" href="/">
                <svg className="css-ze2te4 css-qd6ojx" width={80} height={50} viewBox="0 0 92.105 92.111"><path xmlns="http://www.w3.org/2000/svg" d="m86.887 33.463-28.25-28.25C55.273 1.847 50.803 0 46.05 0s-9.222 1.848-12.58 5.213L5.212 33.463C1.855 36.827 0 41.297 0 46.05s1.855 9.216 5.213 12.58l28.25 28.257c3.359 3.358 7.829 5.213 12.581 5.213s9.222-1.855 12.581-5.213L86.881 58.63a17.68 17.68 0 0 0 5.213-12.581 17.67 17.67 0 0 0-5.207-12.587m-2.584 22.583-28.25 28.256a14.06 14.06 0 0 1-9.997 4.144 14.05 14.05 0 0 1-9.995-4.144L14.313 62.554h18.702l6.226 10.788h13.63l6.816-11.801-6.815-11.801h-13.63l-6.233 10.775H12.274l-4.47-4.47c-2.67-2.67-4.144-6.22-4.144-9.995s1.473-7.325 4.144-9.996l28.25-28.25c2.671-2.671 6.22-4.139 9.996-4.139s7.325 1.468 9.996 4.139L78.2 29.957H59.092l-6.226-10.794H39.235l-6.815 11.8 6.815 11.802h13.63l6.227-10.788h21.14l4.07 4.07a14.05 14.05 0 0 1 4.145 9.997c0 3.782-1.474 7.33-4.144 10.002m-47.66 5.489 4.704-8.148h9.407l4.703 8.148-4.703 8.148h-9.407zm18.814-30.571-4.703 8.147h-9.407l-4.703-8.147 4.703-8.148h9.407zm3.868 6.06-4.537 7.859H37.313l-4.537-7.86h-10.42l-5.213 9.027 5.213 9.025h10.42l4.537-7.859h17.475l4.537 7.86h10.42l5.206-9.026-5.206-9.026z" className="css-s3ak4d" fill="#eaeaea"/></svg>                
                {/* <img src={Logo} width="200px" height="50" alt="LytStar Logo"/> */}
                <span className='navbar-brand'>Lytstar</span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                  <ul className="nav navbar-nav menu_nav justify-content-end">
                    <li className="nav-item">
                      <a className="nav-link" onClick={scrollToTop} href="#">Home</a>
                    {/* <Link activeClass="active" className="nav-link" to="header" spy={true} smooth={true} offset={-70} duration={1000}>Home</Link> */}
                    </li> 
                    <li className="nav-item">
                      {/* <a className="nav-link" href="#about">About</a> */}
                    <Link activeClass="active" className="nav-link" to="about" spy={true} smooth={true} offset={-70} duration={1000}>About</Link>
                    </li> 
                    <li className="nav-item">
                      {/* <a className="nav-link" href="#services">Services</a>  */}
                    <Link activeClass="active" className="nav-link" to="services" spy={true} smooth={true} offset={-70} duration={1000}>Services</Link>
                      </li>
                    {/* <li className="nav-item submenu dropdown">
                      <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                      aria-expanded="false">Pages</a>
                      <ul className="dropdown-menu">
                      <li className="nav-item"><a className="nav-link" href="#">Blog</a></li>
                      <li className="nav-item"><a className="nav-link" href="#">Blog Details</a></li>
                      </ul>
                    </li> */}
                    <li className="nav-item">
                      {/* <a className="nav-link" href="#contact">Contact</a> */}
                    <Link activeClass="active" className="nav-link" to="contact" spy={true} smooth={true} offset={-70} duration={1000}>Contact</Link>
                    </li>
                  </ul>
      
                  {/* <ul className="navbar-right">
                    <li className="nav-item">
                      <button className="button button-header bg">Sign up</button>
                    </li>
                  </ul> */}
                </div> 
              </div>
            </nav>
          </div>
        </header>
        );
}

export default Header;