import {
  Form,
  Button,
  FormGroup,
  FormFeedback,
  Input,
  Col,
  Row,
  Container,
} from "reactstrap";
import { useForm } from "react-hook-form";
import homeIcon from "../../assets/icons/home.svg";
import headphoneIcon from "../../assets/icons/headphone.svg";
import emailIcon from "../../assets/icons/email.svg";
import { useState } from "react";
import axios from "axios";
import { contactPage, endpoints } from "../../constants";

interface ContactFormModel {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const Contact = () => {
  const { register, handleSubmit, errors } = useForm();
  const [showForm, setshowForm] = useState(true);
  const [buttonTitle, setbuttonTitle] = useState(contactPage.SUBMIT_BUTTON_TEXT)
  const [isSubmitting, setisSubmitting] = useState(buttonTitle !== contactPage.SUBMIT_BUTTON_TEXT)

  const onSubmit = (data: ContactFormModel) => {
    if(isSubmitting) return;
    
    setisSubmitting(!isSubmitting);
    setbuttonTitle('Submitting...');
    // console.log(isSubmitting);
    // Send a POST request
    axios.post(endpoints.CONTACT, data)
    .then(function (response) {
      setbuttonTitle(contactPage.SUBMIT_BUTTON_TEXT);
      setisSubmitting(!isSubmitting);
      setshowForm(false);
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    // console.log(endpoints.CONTACT)
    
  };

  /**
   * Render When Form Submitted
   * @returns {*}
   */
  const submitMessage = (): any => {
    return <p className='text-center'>Thank you. We will get in touch.</p>;
  };

  /**
   * Render Contact Form
   * @returns {*}
   */
  const contactForm = (): any => {
    return (
      <Form
        className='form-contact contact_form'
        onSubmit={handleSubmit(onSubmit)}
        id='contactForm'>
        <div className='row'>
          <Col lg={5}>
            <FormGroup>
              <Input
                className='form-control'
                innerRef={register}
                name='name'
                id='name'
                type='text'
                required={true}
                placeholder='Enter your name'
              />
            </FormGroup>
            <FormGroup>
              <Input
                className='form-control'
                innerRef={register}
                name='email'
                id='email'
                required={true}
                type='email'
                placeholder='Enter email address'
              />
            </FormGroup>
            <FormGroup>
              <Input
                className='form-control'
                innerRef={register}
                name='subject'
                id='subject'
                required={true}
                type='text'
                placeholder='Enter Subject'
              />
              <FormFeedback>
                {errors.className &&
                  errors.className.type === "required" &&
                  "Class name is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={7}>
            <FormGroup>
              <Input
                className='form-control different-control w-100'
                type='textarea'
                innerRef={register}
                name='message'
                id='message'
                required={true}
                cols={30}
                rows={5}
                placeholder='Enter Message'
              />
            </FormGroup>
          </Col>
        </div>
        <FormGroup className='text-center text-md-right mt-3'>
          <Button className='button button-contactForm'>{buttonTitle}</Button>
        </FormGroup>
      </Form>
    );
  };

  return (
    <section className='section-padding--small bg-magnolia'>
      <Container>
        <div className='section-intro pb-85px mt-5 text-center'>
          <h2 className='section-intro__title' id='contact'>
            Contact
          </h2>
          <p className='section-intro__subtitle'></p>
        </div>
        <Row>
          <Col md={4} lg={3} mb={4} /*mb-md-0"*/>
            <div className='media contact-info'>
              <span className='contact-info__icon'>
                <img src={homeIcon} alt='home icon' />
              </span>
              <div className='media-body'>
                <h3>LYTSTAR SOLUTIONS</h3>
                <p>Get In touch</p>
              </div>
            </div>
            <div className='media contact-info'>
              <span className='contact-info__icon'>
                <img src={headphoneIcon} alt='headphone icon' />
              </span>
              <div className='media-body'>
                <h3>
                  <a href='tel:080369036740'>0803 6903 6740</a>
                </h3>
                <p>Mon to Fri 9am to 5pm</p>
              </div>
            </div>
            <div className='media contact-info'>
              <span className='contact-info__icon'>
                <img src={emailIcon} alt='email icon' />
              </span>
              <div className='media-body'>
                <h3>
                  <a href='mailto:info@lytstarsolutions.com'>
                    info@lytstarsolutions.com
                  </a>
                </h3>
                <p>Send us your query anytime!</p>
              </div>
            </div>
          </Col>
          <Col md={8} lg={9}>
            {showForm ? contactForm() : submitMessage()}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
