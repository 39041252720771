import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import Home from './components/home';
import './App.scss';
// import './assets/js/main.js';
function App() {
  return (
    <HashRouter>
      <Switch>
          <Route path="/" name="Home" render={props => <Home {...props}/>}/>
          <Redirect from="*" to="/" exact={true}/>
      </Switch>
    </HashRouter>
  );
}

export default App;
