import React from 'react'
import Footer from '../layout/footer';
import Header from '../layout/header';
import About from './about';
import Hero from './hero';
import Services from './services';
import Contact from './contact';

import '../../assets/js/main.js';
interface Props {

}

const Home = (props: Props) => {
    return (
        <>
        <Header />
        <main className="side-main">
        <Hero />
        <About />
        <Services />
        <Contact />
        </main>
        <Footer />
        </>
    );
}

export default Home;
